<template>
  <div>
    <b-card title="7 Tage TTE" class="sieben-tage">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-card class="bordered-card" title="Klappenstatus">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Mitralinsuffizienz"
                        label-for="sieben_tage_tte_klappenstatus_mitralinsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.sieben_tage_tte_klappenstatus_mitralinsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Mitralinsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Mitralstenose"
                        label-for="sieben_tage_tte_klappenstatus_mitralstenose"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.sieben_tage_tte_klappenstatus_mitralstenose
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Mitralstenose"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Aorteninsuffizienz"
                        label-for="sieben_tage_tte_klappenstatus_aorteninsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.sieben_tage_tte_klappenstatus_aorteninsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Aorteninsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Aortenstenose"
                        label-for="sieben_tage_tte_klappenstatus_aortenstenose"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.sieben_tage_tte_klappenstatus_aortenstenose
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Aortenstenose"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Trikuspidalklappeninsuffizienz"
                        label-for="sieben_tage_tte_klappenstatus_trikuspidalklappeninsuff"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.sieben_tage_tte_klappenstatus_trikuspidalklappeninsuff
                          "
                          :options="[
                            'keine',
                            'leicht',
                            'mittel',
                            'schwer',
                            ...nichterhoben,
                          ]"
                          :disabled="readOnly"
                          placeholder="Trikuspidalklappeninsuffizienz"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-card class="bordered-card" title="Rechter Ventrikel">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Gemessener Gradient über Triskuspidalklappe [mmHg]"
                        label-for="sieben_tage_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.sieben_tage_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe
                          "
                          :options="nichterhoben"
                          taggable
                          :disabled="readOnly"
                          placeholder=""
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="TAPSE [mm]"
                        label-for="sieben_tage_tte_rechter_ventrikel_tapse"
                        label-cols-md="4"
                      >
                        <v-select
                          v-model="
                            patientInfo.sieben_tage_tte_rechter_ventrikel_tapse
                          "
                          :options="nichterhoben"
                          taggable
                          :disabled="readOnly"
                          placeholder="TAPSE [mm]"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card title="7 Tage" class="sieben-tage">
      <b-form @submit.prevent class="px-2">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Status"
                  label-for="sieben_tage_status"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="patientInfo.sieben_tage_status"
                    :options="['entlassen', 'tot', 'stationär']"
                    :disabled="readOnly"
                    placeholder="Status"
                  />
                </b-form-group>
              </b-col>
              <template v-if="patientInfo.sieben_tage_status === 'tot'">
                <b-col cols="12" key="siebenstatustotdatum">
                  <b-form-group
                    label="Todesdatum"
                    label-for="sieben_tage_status_tot_date"
                    label-cols-md="4"
                  >
                    <b-input-group class="mb-1">
                      <b-form-input
                        id="sieben_tage_status_tot_date"
                        v-model="patientInfo.sieben_tage_status_tot_date"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date1"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" key="siebenstatustotursache">
                  <b-form-group
                    label="Todesursache"
                    label-for="sieben_tage_status_tot_ursache"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.sieben_tage_status_tot_ursache"
                      id="sieben_tage_status_tot_ursache"
                      type="text"
                      placeholder="Todesursache"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </template>
              <template v-if="patientInfo.sieben_tage_status === 'entlassen'">
                <b-col cols="12" key="siebenstatusentlassungsdatum">
                  <b-form-group
                    label="Entlassungsdatum"
                    label-for="sieben_tage_status_entlassen_date"
                    label-cols-md="4"
                  >
                    <b-input-group class="mb-1">
                      <b-form-input
                        id="sieben_tage_status_entlassen_date"
                        v-model="patientInfo.sieben_tage_status_entlassen_date"
                        type="text"
                        placeholder="DD.MM.YYYY"
                        v-mask="dateMask"
                        autocomplete="off"
                        show-decade-nav
                        :readonly="readOnly"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="date"
                          value-as-date
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="de"
                          aria-controls="example-input"
                          :disabled="readOnly"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <template v-if="patientInfo.sieben_tage_status === 'stationär'">
      <b-card
        title="Last time seen stationär"
        class="sieben-tage"
        key="lasttimeseenstationaercard"
      >
        <b-form @submit.prevent class="px-2">
          <b-row>
            <b-col md="6">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Status"
                    label-for="last_stationaer_seen_status"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.last_stationaer_seen_status"
                      :options="['entlassen', 'tot']"
                      :disabled="readOnly"
                      placeholder="Status"
                    />
                  </b-form-group>
                </b-col>
                <template
                  v-if="patientInfo.last_stationaer_seen_status === 'entlassen'"
                >
                  <b-col cols="12" key="laststatentlassdatum">
                    <b-form-group
                      label="Entlassungsdatum"
                      label-for="last_stationaer_seen_status_entlassen_date"
                      label-cols-md="4"
                    >
                      <b-input-group class="mb-1">
                        <b-form-input
                          id="last_stationaer_seen_status_entlassen_date"
                          v-model="
                            patientInfo.last_stationaer_seen_status_entlassen_date
                          "
                          type="text"
                          placeholder="DD.MM.YYYY"
                          v-mask="dateMask"
                          autocomplete="off"
                          show-decade-nav
                          :readonly="readOnly"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="date2"
                            value-as-date
                            show-decade-nav
                            button-only
                            button-variant="outline-primary"
                            right
                            size="sm"
                            locale="de"
                            aria-controls="example-input"
                            :disabled="readOnly"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </template>

                <template
                  v-if="patientInfo.last_stationaer_seen_status === 'tot'"
                >
                  <b-col cols="12" key="laststattotdatum">
                    <b-form-group
                      label="Todesdatum"
                      label-for="last_stationaer_seen_status_tot_date"
                      label-cols-md="4"
                    >
                      <b-input-group class="mb-1">
                        <b-form-input
                          id="last_stationaer_seen_status_tot_date"
                          v-model="
                            patientInfo.last_stationaer_seen_status_tot_date
                          "
                          type="text"
                          placeholder="DD.MM.YYYY"
                          v-mask="dateMask"
                          autocomplete="off"
                          show-decade-nav
                          :readonly="readOnly"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="date3"
                            value-as-date
                            show-decade-nav
                            button-only
                            button-variant="outline-primary"
                            right
                            size="sm"
                            locale="de"
                            aria-controls="example-input"
                            :disabled="readOnly"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Todesursache"
                      label-for="last_stationaer_seen_status_tot_ursache"
                      label-cols-md="4"
                    >
                      <b-form-input
                        v-model="
                          patientInfo.last_stationaer_seen_status_tot_ursache
                        "
                        id="last_stationaer_seen_status_tot_ursache"
                        type="text"
                        placeholder="Todesursache"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </template>
                <b-col cols="12"></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card
        title="Last time seen stationär TTE"
        class="sieben-tage"
        key="laststimeseenstationaerttecard"
      >
        <b-form @submit.prevent class="px-2">
          <b-row>
            <b-col md="6">
              <b-row>
                <b-col cols="12">
                  <b-card class="bordered-card" title="Klappenstatus">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Mitralinsuffizienz"
                          label-for="last_stationaer_seen_tte_klappenstatus_mitralinsuff"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="
                              patientInfo.last_stationaer_seen_tte_klappenstatus_mitralinsuff
                            "
                            :options="[
                              'keine',
                              'leicht',
                              'mittel',
                              'schwer',
                              ...nichterhoben,
                            ]"
                            :disabled="readOnly"
                            placeholder="Mitralinsuffizienz"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Mitralstenose"
                          label-for="last_stationaer_seen_tte_klappenstatus_mitralstenose"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="
                              patientInfo.last_stationaer_seen_tte_klappenstatus_mitralstenose
                            "
                            :options="[
                              'keine',
                              'leicht',
                              'mittel',
                              'schwer',
                              ...nichterhoben,
                            ]"
                            :disabled="readOnly"
                            placeholder="Mitralstenose"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Aorteninsuffizienz"
                          label-for="last_stationaer_seen_tte_klappenstatus_aorteninsuff"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="
                              patientInfo.last_stationaer_seen_tte_klappenstatus_aorteninsuff
                            "
                            :options="[
                              'keine',
                              'leicht',
                              'mittel',
                              'schwer',
                              ...nichterhoben,
                            ]"
                            :disabled="readOnly"
                            placeholder="Aorteninsuffizienz"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Aortenstenose"
                          label-for="last_stationaer_seen_tte_klappenstatus_aortenstenose"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="
                              patientInfo.last_stationaer_seen_tte_klappenstatus_aortenstenose
                            "
                            :options="[
                              'keine',
                              'leicht',
                              'mittel',
                              'schwer',
                              ...nichterhoben,
                            ]"
                            :disabled="readOnly"
                            placeholder="Aortenstenose"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Trikuspidalklappeninsuffizienz"
                          label-for="last_stationaer_seen_tte_klappenstatus_trikuspidalklappeninsuff"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="
                              patientInfo.last_stationaer_seen_tte_klappenstatus_trikuspidalklappeninsuff
                            "
                            :options="[
                              'keine',
                              'leicht',
                              'mittel',
                              'schwer',
                              ...nichterhoben,
                            ]"
                            :disabled="readOnly"
                            placeholder="Trikuspidalklappeninsuffizienz"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col cols="12">
                  <b-card class="bordered-card" title="Rechter Ventrikel">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Gemessener Gradient über Triskuspidalklappe [mmHg]"
                          label-for="last_stationaer_seen_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="
                              patientInfo.last_stationaer_seen_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe
                            "
                            :options="nichterhoben"
                            taggable
                            :disabled="readOnly"
                            placeholder=""
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="TAPSE [mm]"
                          label-for="last_stationaer_seen_tte_rechter_ventrikel_tapse"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="
                              patientInfo.last_stationaer_seen_tte_rechter_ventrikel_tapse
                            "
                            :options="nichterhoben"
                            taggable
                            :disabled="readOnly"
                            placeholder="TAPSE [mm]"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { convertDate2Str, dateMask } from "@core/utils/utils";
import { mapState, mapActions } from "vuex";
import config from "@/config";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  timers: {
    updatePatient: config.TIMER_OPTION,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        sieben_tage_tte_klappenstatus_mitralinsuff: "",
        sieben_tage_tte_klappenstatus_mitralstenose: "",
        sieben_tage_tte_klappenstatus_aorteninsuff: "",
        sieben_tage_tte_klappenstatus_aortenstenose: "",
        sieben_tage_tte_klappenstatus_trikuspidalklappeninsuff: "",
        sieben_tage_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe:
          "",
        sieben_tage_tte_rechter_ventrikel_tapse: "",

        sieben_tage_status: "",
        sieben_tage_status_entlassen_date: "",
        sieben_tage_status_tot_date: "",
        sieben_tage_status_tot_ursache: "",

        last_stationaer_seen_status: "",
        last_stationaer_seen_status_entlassen_date: "",
        last_stationaer_seen_status_tot_date: "",
        last_stationaer_seen_status_tot_ursache: "",

        last_stationaer_seen_tte_klappenstatus_mitralinsuff: "",
        last_stationaer_seen_tte_klappenstatus_mitralstenose: "",
        last_stationaer_seen_tte_klappenstatus_aorteninsuff: "",
        last_stationaer_seen_tte_klappenstatus_aortenstenose: "",
        last_stationaer_seen_tte_klappenstatus_trikuspidalklappeninsuff: "",
        last_stationaer_seen_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe:
          "",
        last_stationaer_seen_tte_rechter_ventrikel_tapse: "",
      },
      hospital: null,
      hospitals: [],
      yesno: ["ja", "nein"],
      nichterhoben: ["nicht erhoben"],
      jnnichterhoben: ["ja", "nein", "nicht erhoben"],
      birth: "",
      gender: "",
      date: null,
      date1: null,
      date2: null,
      date3: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    date(v, ov) {
      if (v) {
        this.patientInfo.sieben_tage_status_entlassen_date = convertDate2Str(v);
      }
    },
    date1(v, ov) {
      if (v) {
        this.patientInfo.sieben_tage_status_tot_date = convertDate2Str(v);
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.last_stationaer_seen_status_entlassen_date =
          convertDate2Str(v);
      }
    },
    date3(v, ov) {
      if (v) {
        this.patientInfo.last_stationaer_seen_status_tot_date =
          convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    this.fetchHospitals();
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  // beforeDestroy() {
  //   this.updatePatient()
  // },
  methods: {
    dateMask,
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let activeElement = document.activeElement;
      let blockingElements = ["input", "textarea"];

      // check whether an input field is the active elemnent,
      //if it is, do not interrupt the users entries with updating
      // the patientInfo but return instead
      if (
        activeElement &&
        blockingElements.indexOf(activeElement.tagName.toLowerCase()) !== -1
      ) {
        return;
      }

      this.updatePatientById(this.patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    fetchHospitals() {
      this.$http
        .get("/hospitals/list_for_update")
        .then((res) => {
          this.hospitals = res.data.hospitals;
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while fetching hospitals."
          );
        });
    },
    handleSave() {
      this.$http
        .patch(`/patients/update/${this.$route.params.id}`, this.patientInfo)
        .then((res) => {
          this.showToast("success", "Success", "Successfully updated.");
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while saving the patient."
          );
        });
    },
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.sieben_tage_tte_klappenstatus_mitralinsuff =
        v.sieben_tage_tte_klappenstatus_mitralinsuff;
      this.patientInfo.sieben_tage_tte_klappenstatus_mitralstenose =
        v.sieben_tage_tte_klappenstatus_mitralstenose;
      this.patientInfo.sieben_tage_tte_klappenstatus_aorteninsuff =
        v.sieben_tage_tte_klappenstatus_aorteninsuff;
      this.patientInfo.sieben_tage_tte_klappenstatus_aortenstenose =
        v.sieben_tage_tte_klappenstatus_aortenstenose;
      this.patientInfo.sieben_tage_tte_klappenstatus_trikuspidalklappeninsuff =
        v.sieben_tage_tte_klappenstatus_trikuspidalklappeninsuff;
      this.patientInfo.sieben_tage_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe =
        v.sieben_tage_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe;
      this.patientInfo.sieben_tage_tte_rechter_ventrikel_tapse =
        v.sieben_tage_tte_rechter_ventrikel_tapse;

      // SIEBEN TAGE STATUS
      this.patientInfo.sieben_tage_status = v.sieben_tage_status;
      this.patientInfo.sieben_tage_status_entlassen_date =
        v.sieben_tage_status_entlassen_date;
      this.patientInfo.sieben_tage_status_tot_date =
        v.sieben_tage_status_tot_date;
      this.patientInfo.sieben_tage_status_tot_ursache =
        v.sieben_tage_status_tot_ursache;
      this.patientInfo.last_stationaer_seen_status =
        v.last_stationaer_seen_status;

      // LAST SEEN STATIONAER STATUS
      this.patientInfo.last_stationaer_seen_status_entlassen_date =
        v.last_stationaer_seen_status_entlassen_date;
      this.patientInfo.last_stationaer_seen_status_tot_date =
        v.last_stationaer_seen_status_tot_date;

      this.patientInfo.last_stationaer_seen_status_tot_ursache =
        v.last_stationaer_seen_status_tot_ursache;
      this.patientInfo.last_stationaer_seen_tte_klappenstatus_mitralinsuff =
        v.last_stationaer_seen_tte_klappenstatus_mitralinsuff;
      this.patientInfo.last_stationaer_seen_tte_klappenstatus_mitralstenose =
        v.last_stationaer_seen_tte_klappenstatus_mitralstenose;
      this.patientInfo.last_stationaer_seen_tte_klappenstatus_aorteninsuff =
        v.last_stationaer_seen_tte_klappenstatus_aorteninsuff;
      this.patientInfo.last_stationaer_seen_tte_klappenstatus_aortenstenose =
        v.last_stationaer_seen_tte_klappenstatus_aortenstenose;
      this.patientInfo.last_stationaer_seen_tte_klappenstatus_trikuspidalklappeninsuff =
        v.last_stationaer_seen_tte_klappenstatus_trikuspidalklappeninsuff;
      this.patientInfo.last_stationaer_seen_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe =
        v.last_stationaer_seen_tte_rechter_ventrikel_gemess_gradient_ueber_trikuspidalklappe;
      this.patientInfo.last_stationaer_seen_tte_rechter_ventrikel_tapse =
        v.last_stationaer_seen_tte_rechter_ventrikel_tapse;
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.sieben-tage {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
